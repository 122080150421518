import { Dayjs } from 'dayjs'
import { User } from '../user/user-typed'
import { Transaction } from '../transaction/transaction-typed'

export enum EnumGiveawayStatus {
  PENDING = 'pending',
  SUCCESS = 'success',
  FAIL = 'fail',
}

export enum EnumGiveawayUnit {
  gram = 'gram',
  baht = 'baht',
}

export type ICreateGiveawayBody = {
  tmnId: string
  prefix: string
  amount: number
  unit: any
  campaign: string
  otp?: string
  otpId?: string
}

export type IPageMeta = {
  currentPage: number
  itemCount: number
  itemsPerPage: number
  totalItems: number
  totalPages: number
}

export type IGiveaway = {
  id: string
  createdAt: string
  updatedAt: string
  deletedAt: any
  userId: string
  amount: number
  status: string
  prefix: string
  campaignName: string
  unit: string
  creatorId: string
  transactionId: string
  user: User
  transaction?: Transaction
  errorMessage?: string
}

export type IGiveawayList = {
  items: IGiveaway[]
  meta: IPageMeta
}

export type IExportGiveawayReportParams = {
  prefix?: string
  startDate: Dayjs
  endDate: Dayjs
  status?: EnumGiveawayStatus
  unit?: EnumGiveawayUnit
}

export type IGiveAwayFileBody = {
  file: File | null
  otpData: {
    otp?: string
    otpId?: string
  }
}
