import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Typography } from '@material-ui/core'
import styled from '@emotion/styled'
import { customAddressLabel, useRouter } from '../../../../utils/helper'
import {
  useFetchBalance,
  useGetPort,
  useSellClearPortByPortId,
} from '../../../services/port/port-query'
import DetailPage from '../../../components/templates/DetailPage'
import numeral from 'numeral'
import UserCard from '../../user/UserProfile/UserCard'
import { Address, SellType } from '../../../services/port/port-typed'
import { useGetImageUser } from '../../../services/user/user-query'
import ImageViewer from '../../../components/common/ImageViewer'
import TransactionCard from '../../transaction/TransactionDetail/TransactionCard'
import Text from '../../../components/common/Text'
import { TransactionList } from './TransactionList'
import Button from '../../../components/common/Button'
import { SellGoldModal, SellGoldOption } from './SellGoldModal'
import { Authorize } from '../../../components/Authorize'
import { ROLE } from '../../../services/user/user-typed'
import { appConfig } from '../../../constants/app-config'
import { useOtpMobileByToken } from '../../../services/otp/otp-query'
import { OtpAction } from '../../../services/otp/otp-types'
import OTPModal from '../../../components/common/OTPModal'
import { useTranslation } from 'react-i18next'

import { useToastForReactQuery } from '../../../../utils/custom-hooks'
import { Spinner } from 'react-bootstrap'

const Layout = styled.div`
  display: flex;
  flex: 1;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding-right: 16px;
`

const TextLib = styled(Typography)`
  line-height: 2 !important;
`

const Grid = styled.div`
  display: grid;
  grid-gap: 10px;
  margin-top: 10px;
`

const Flex = styled(Grid)`
  display: flex;
  grid-gap: 10px;
  margin-top: 0px;
`
const TextColor = styled(TextLib)<{ color?: string }>`
  color: ${({ color }) => color};
  font-weight: bold !important;
`

type OTPState = {
  id: string
  refCode: string
}
const PortDetail = () => {
  const { portId } = useRouter().query
  const { data: port } = useGetPort(portId)
  const { mutateAsync: requestOtp } = useOtpMobileByToken()
  const { mutateAsync: sellClearPort } = useSellClearPortByPortId()
  const { t } = useTranslation()
  const toast = useToastForReactQuery()
  const [isVisibleSellGoldModal, setIsVisibleSellGoldModal] = useState(false)
  const [sellType, setSellType] = useState<SellType | undefined>(undefined)
  const [otpErrorMessage, setOtpErrorMessage] = useState('')
  const [otpModalVisible, setOtpModalVisible] = useState(false)
  const [otpState, setOtpState] = useState<OTPState | null>(null)
  const { mutateAsync: fetchBalance, isLoading: fetchBalanceLoading } = useFetchBalance()

  const address = useMemo(() => {
    const obj: Partial<Record<Address['type'], Address>> = {}
    port?.address.forEach(data => {
      obj[data.type] = data
    })
    return obj
  }, [port])

  const { userId = '', sgGoldBalance = '', attachments = [], user, sgUserId = '' } = port || {}
  const { firstnameTh = '', lastnameTh = '' } = user || {}
  const fullname = `${firstnameTh} ${lastnameTh}`
  const { date = '' } = port?.dcaPlan || {}
  let b = Number(sgGoldBalance)
  const goldBalance = b / 15.244
  const balance = numeral(goldBalance).format('0,0.0000')
  const dcaDate = date ? `every ${date} of mount` : '-'
  const [fontCitizenId, setFontCitizenId] = useState('')
  const [backCitizenId, setBackCitizenId] = useState('')
  const [holdCitizenId, setHoldCitizenId] = useState('')

  useEffect(() => {
    attachments.forEach(({ type, id: imageId }) => {
      if (type === 'holdCitizen') {
        setHoldCitizenId(imageId)
      } else if (type === 'citizen') {
        setFontCitizenId(imageId)
      } else if (type === 'laserCode') {
        setBackCitizenId(imageId)
      }
    })
  }, [attachments])

  const { data: imageCitizen } = useGetImageUser(fontCitizenId, 'origin')
  const { image: imageCitizenPhoto } = imageCitizen! || '-'
  const { data: imageBackCitizen } = useGetImageUser(backCitizenId, 'origin')
  const { image: imageBackCitizenPhoto } = imageBackCitizen! || '-'
  const { data: imageHoldCitizen } = useGetImageUser(holdCitizenId, 'origin')
  const { image: imageHoldCitizenPhoto } = imageHoldCitizen! || '-'

  const showPhoto = useMemo(() => {
    if (!imageCitizenPhoto) {
      return 0
    } else if (!imageBackCitizenPhoto) {
      return 0
    } else if (!imageHoldCitizenPhoto) {
      return 0
    }
  }, [imageCitizenPhoto, imageHoldCitizenPhoto, imageBackCitizenPhoto])

  const portData = useMemo(
    () => [
      {
        label: 'Registered Address',
        value: customAddressLabel(address.citizen),
      },
      {
        label: 'Present Address',
        value: customAddressLabel(address.recent),
      },
      {
        label: 'Work Address ',
        value: customAddressLabel(address.work),
      },
      { label: 'DCA date', value: dcaDate },
      { label: 'Safe Gold User Id', value: sgUserId },
      {
        label: 'Attachments',
        value:
          showPhoto === 0 ? (
            <Text bold>-</Text>
          ) : (
            <ImageViewer
              images={[imageCitizenPhoto, imageBackCitizenPhoto, imageHoldCitizenPhoto]}
            />
          ),
      },
    ],
    [
      address.citizen,
      address.recent,
      address.work,
      dcaDate,
      imageCitizenPhoto,
      imageHoldCitizenPhoto,
      imageBackCitizenPhoto,
      sgUserId,
      showPhoto,
    ],
  )

  const sellOptions: SellGoldOption[] = useMemo(() => {
    const sellOptions: SellGoldOption[] = []
    if (appConfig.ENABLE_FEATURE_SELL_TO_BANK) {
      sellOptions.push({
        sellType: SellType.BANK,
        title: 'Bank',
      })
    }
    if (appConfig.ENABLE_FEATURE_SELL_TO_TMN) {
      sellOptions.push({
        sellType: SellType.TMNWallet,
        title: 'True Money Wallet',
      })
    }
    return sellOptions
  }, [])

  const isHaveSellOptions = useMemo(() => sellOptions.length > 0, [sellOptions])

  const onClickSellGold = useCallback(() => {
    setIsVisibleSellGoldModal(true)
  }, [])

  const onCloseSellGoldModal = useCallback(() => {
    setIsVisibleSellGoldModal(false)
  }, [])

  const onConfirmSellGold = useCallback(
    async (sellType?: SellType) => {
      if (sellType) {
        setSellType(sellType)
      }

      setIsVisibleSellGoldModal(false)

      try {
        const otpResponse = await requestOtp({
          action: OtpAction.MANUAL_SELL,
        })

        setOtpState({ id: otpResponse.otpId, refCode: otpResponse.refCode })

        setOtpModalVisible(true)
        setOtpErrorMessage('') // Reset OTP error message
      } catch (error) {
        toast.open(t(error), {
          type: 'error',
          autoClose: 3 * 1000,
        })
      }
    },
    [requestOtp, t, toast],
  )

  const handleOTPConfirm = useCallback(
    async (otp: string) => {
      const sellClearPortParams = {
        portId,
        sellType,
        otp,
        otpId: otpState?.id,
      }
      sellClearPort(sellClearPortParams, {
        onSuccess: () => {
          toast.open('Success', {
            type: 'success',
            autoClose: 3 * 1000,
          })
          setOtpModalVisible(false)
        },
        onError: error => {
          if (error === '422-022') {
            setOtpErrorMessage(t(error))
          } else {
            toast.open('Can not create sell port', {
              type: 'error',
              autoClose: 3 * 1000,
            })
            setOtpModalVisible(false)
          }
        },
      })
    },
    [otpState, portId, sellClearPort, sellType, t, toast],
  )

  // internal-ports/fetch-balances
  const onClickRefreshBalance = useCallback(async () => {
    try {
      await fetchBalance({ userIds: [userId] })
    } catch (error) {}
  }, [fetchBalance, userId])

  return (
    <div>
      <DetailPage
        title={
          <Flex>
            <TextColor variant="h5">Balance</TextColor>
            <TextColor variant="h5">{balance ? `${balance} Baht Gold` : '-'}</TextColor>
            <Layout>
              <Button
                onClick={onClickRefreshBalance}
                variant={'secondary'}
                disabled={fetchBalanceLoading}
              >
                {fetchBalanceLoading && (
                  <Spinner
                    animation="border"
                    style={{
                      marginBottom: '-4px',
                      marginRight: '8px',
                    }}
                  />
                )}
                Refresh Balance
              </Button>

              {goldBalance > 0 &&
                (appConfig.ENABLE_FEATURE_SELL_MANUAL &&
                appConfig.ENABLE_FEATURE_TRUE_MONEY &&
                isHaveSellOptions ? (
                  <Authorize role={ROLE.SUPER_ADMIN}>
                    <Button onClick={onClickSellGold} variant={'warning'}>
                      Cancel Saving Gold
                    </Button>
                  </Authorize>
                ) : null)}
            </Layout>
          </Flex>
        }
        contents={portData}
        gridItems={[
          <UserCard userId={userId} />,
          <TransactionCard portId={portId} fullname={fullname} />,
        ]}
      />
      <div
        style={{
          marginTop: 10,
        }}
      >
        <TransactionList portId={portId} />
      </div>
      {isVisibleSellGoldModal && (
        <SellGoldModal
          isOpen={isVisibleSellGoldModal}
          onConfirm={onConfirmSellGold}
          onClosed={onCloseSellGoldModal}
          balance={sgGoldBalance}
          sellOptions={sellOptions}
        />
      )}

      <OTPModal
        show={otpModalVisible}
        handleClose={() => setOtpModalVisible(false)}
        handleConfirm={handleOTPConfirm}
        otpRef={otpState?.refCode}
        errorMessage={otpErrorMessage}
      />
    </div>
  )
}

export default PortDetail
