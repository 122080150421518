import qs from 'querystring'

type ParamType = { [p: string]: any } | undefined
type Option<T extends ParamType, V extends ParamType> = {
  routeParam?: T
  queryParam?: V
}

const generate = <T extends ParamType = undefined, V extends ParamType = undefined>(
  url: string,
) => (option?: Option<T, V>) => {
  const { routeParam, queryParam } = option || {}
  let newQueryParam = ''
  if (queryParam) {
    newQueryParam = `?${qs.stringify(queryParam)}`
  }

  let newUrl = url
  if (routeParam) {
    const urls = url.split('/')
    newUrl = urls
      .map(u => {
        if (/:.+/.test(u)) {
          return routeParam[u.slice(1)]
        }
        return u
      })
      .join('/')
  }

  return `${newUrl}${newQueryParam}`
}

export const login = generate(`/auth/login`)
export const portDetail = generate<{ portId: string }>(`/ports/:portId`)
export const ports = generate<{ q?: string; page?: string }>('/ports')
export const userProfile = generate<{ userId: string }>(`users/:userId`)
export const users = generate<{ q?: string; page?: string }>('/users')
export const transactionDetail = generate<{ transactionId: string }>(`/transactions/:transactionId`)
export const transactions = generate<{ q?: string; page?: string }>('/transactions')
export const kycDetail = generate<{ kycId: string }>(`/ekycs/:kycId`)
export const kycs = generate<{ q?: string; page?: string }>('/ekycs')
export const sellLimit = generate<{ q?: string; page?: string }>('/sell-limit')

export const notification = generate<undefined, { q?: string; page?: string }>('/notification')
export const notificationDetail = generate(`${notification()}/:notificationId`)
export const notificationCreate = generate(`${notification()}/news`)
export const bankAccountDetail = generate<{ bankAccountId: string }>(
  `/bank_accounts/:bankAccountId`,
)
export const bankAccounts = generate<{ q?: string; page?: string }>('/bank_accounts')
export const article = generate<undefined, { q?: string; page?: string }>(`/article`)
export const createArticle = generate(`${article()}/create`)
export const articleDetail = generate(`${article()}/detail/:id`)

export const banner = generate<undefined, { q?: string; page?: string }>(`/banner`)
export const bannerCreate = generate(`${banner()}/create`)
export const bannerDetail = generate(`${banner()}/detail/:id`)

export const setting = generate(`/setting`)

export const rewards = generate<{ q?: string; page?: string }>(`/rewards`)
export const rewardDetail = generate<{ giveawayId: string }>(`${rewards()}/:giveawayId`)
export const rewardCreate = generate(`/imports`)

export const campaign = generate<{ q?: string; page?: string }>(`/campaign`)
export const createCampaign = generate(`${campaign()}/create`)
export const campaignDetail = generate(`${campaign()}/detail/:id`)
