import React from 'react'
import {
  getColorTransactionStatus,
  getTransactionTypeColor,
  isDevelopment,
  replaceUnderScoreAndCapitalize,
  useRouter,
} from '../../../../utils/helper'
import DetailPage, { ContentType } from '../../../components/templates/DetailPage'
import Text from '../../../components/common/Text'
import styled from '@emotion/styled'

import {
  useGetGoldProductDetail,
  useGetGoldProductDetailById,
  useGetTransaction,
} from '../../../services/transaction/transaction-query'
import DeliveryCard from './DeliveryCard'
import PaymentCard from './PaymentCard'
import numeral from 'numeral'
import dayjs from 'dayjs'
import PortCard from '../../port/PortDetail/PortCard'
import { useMemo } from 'react'
import SafeGoldCallBacksCard from './SafeGoldCallBacksCard'
import { compact, first } from 'lodash'
import {
  EnumTransactionType,
  EnumTransactionTypeCapitalize,
  GoldProductType,
} from '../../../services/transaction/transaction-typed'
import { Card, CardMedia } from '@material-ui/core'

const Grid = styled.div`
  display: grid;
  grid-gap: 10px;
  margin-top: 10px;
`

const Flex = styled(Grid)`
  display: flex;
  grid-gap: 10px;
  margin-top: 0px;
`

const TextColor = styled(Text)<{ color: string }>`
  color: ${({ color }) => color};
  font-weight: bold !important;
`
export const statusDelivery = (type?: EnumTransactionType | EnumTransactionTypeCapitalize) => {
  if (
    type === EnumTransactionType.ONE_TIME_DELIVERY ||
    type === EnumTransactionTypeCapitalize.ONE_TIME_DELIVERY
  ) {
    return true
  } else return false
}

export const TransactionDetail = () => {
  const { query } = useRouter()
  const { transactionId } = query || {}
  const { data: transaction } = useGetTransaction(transactionId)

  const {
    txId,
    type,
    status,
    goldAmount = 0,
    price = '',
    sgRate = '',
    bankAccountNo = '',
    bankName = '',
    createdAt = '',
    portId = '',
    payment,
    reference = '',
    invoiceLink = '',
    sgUserId = '',
    promoCodeDetail,
    txIdCc = '',
    patternId = '',
    errorMessage,
    spentGoldAmount = 0,
  } = transaction ?? {}

  const { data: goldProductDetailById } = useGetGoldProductDetailById(patternId)

  const goldDetail = useMemo(() => {
    return first(goldProductDetailById?.data.products)
  }, [goldProductDetailById])

  const attachment = useMemo(() => {
    return first(goldDetail?.images)
  }, [goldDetail])

  const transactionDate = useMemo(() => {
    const totalGoldAmount = spentGoldAmount + goldAmount
    const totalPrice = Number(sgRate) * totalGoldAmount
    const priceGold = numeral(totalPrice).format('0,0.00')
    const priceRate = numeral(sgRate).format('0,0.00')
    const promoCodeContent: ContentType[] = promoCodeDetail?.promoCode
      ? [
          {
            label: 'Promo Code',
            value: promoCodeDetail?.promoCode ? (
              <div>
                <Text bold>{promoCodeDetail?.promoCode || '-'}</Text>
                <Text bold>
                  {[
                    `${numeral(promoCodeDetail?.rewardPrice || 0).format('0,0.00')} บาท`,
                    `(${promoCodeDetail?.rewardAmount} g.)`,
                  ].join(' ')}
                </Text>
                <Text bold>{promoCodeDetail?.message}</Text>
              </div>
            ) : (
              '-'
            ),
            position: 'left',
          },
        ]
      : []

    const data: (ContentType | undefined)[] = [
      {
        label: 'Gold amount',
        value: totalGoldAmount > 0 ? `${totalGoldAmount} g.` : '-',
        position: 'left',
      },
      { label: 'Price', value: priceGold ? `${priceGold} THB` : '-', position: 'left' },
      { label: 'Rate', value: sgRate ? `${priceRate} THB/g.` : '-', position: 'left' },
      {
        label: 'Invoice Link',
        value: invoiceLink ? (
          <a href={invoiceLink} rel="noopener noreferrer" target="_blank">
            <Text bold>{invoiceLink.split('/').pop()}</Text>
          </a>
        ) : (
          '-'
        ),
        position: 'left',
      },
      ...promoCodeContent,
      isDevelopment
        ? {
            label: 'Safe Gold User Id',
            value: sgUserId ? sgUserId : '-',
            position: 'right',
          }
        : undefined,
      { label: 'Tx Id', value: txId ? txId : '-', position: 'right' },

      {
        label: 'Time',
        value: createdAt ? dayjs(createdAt).format('DD MMMM YYYY HH:mm') : '-',
        position: 'right',
      },
      {
        label: 'Reference',
        value: reference || '-',
        position: 'right',
      },
      {
        label: 'Bank Account',
        value: bankAccountNo && bankName ? `${bankAccountNo} - ${bankName}` : '-',
        position: 'right',
      },
      {
        label: 'Transaction Blockchain Id',
        value: txIdCc ? `${txIdCc}` : '-',
        position: 'right',
      },
      {
        label: 'Error Message',
        value: errorMessage ? `${errorMessage}` : '-',
        position: 'right',
      },
    ]
    return compact(data)
  }, [
    bankAccountNo,
    bankName,
    createdAt,
    errorMessage,
    goldAmount,
    invoiceLink,
    promoCodeDetail,
    reference,
    sgRate,
    sgUserId,
    spentGoldAmount,
    txId,
    txIdCc,
  ])

  return transaction ? (
    <div>
      <DetailPage
        title={
          <Flex>
            <TextColor variant="h5" color={getTransactionTypeColor(type)}>
              {replaceUnderScoreAndCapitalize(type)}
            </TextColor>
            <TextColor variant="h5" color={getColorTransactionStatus(status)}>
              ({replaceUnderScoreAndCapitalize(status)})
            </TextColor>
          </Flex>
        }
        contents={transactionDate}
        gridItems={[
          <>
            <PortCard portId={portId} />
            {payment ? <PaymentCard payment={payment!} /> : null}
            {statusDelivery(type) === true && (
              <DeliveryCard transactionIdAPI={transactionId} attachment={attachment} />
            )}
          </>,
        ]}
      />
      <SafeGoldCallBacksCard transaction={transaction} />
    </div>
  ) : (
    <></>
  )
}
export default TransactionDetail
